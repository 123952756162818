// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.t_OwI0XZJXFQiALyEWqD{display:flex;flex-direction:column;margin-left:auto}.t_OwI0XZJXFQiALyEWqD>div>span{font-weight:bold;margin-right:4px}.g2qXk0eH9UW5kZFZ18SK{margin-top:26px}`, ""]);
// Exports
export var info = `t_OwI0XZJXFQiALyEWqD`;
export var financeButton = `g2qXk0eH9UW5kZFZ18SK`;
export default ___CSS_LOADER_EXPORT___;
